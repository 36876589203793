import React from 'react';

const AppInput = React.forwardRef(
	(
		{
			label,
			showErrorMessage = true,
			errors,
			containerClassName,
			defaultErrorMessage = '',
			successMessage = '',
			...props
		},
		ref
	) => {
		const { name } = props;

		return (
			<div
				className={`app-input-container ${!!props.value ? 'input-with-value' : 'input-without-value'} ${
					containerClassName ? containerClassName : ''
				}`}
			>
				<span className={'app-text-label'}>{props.placeholder}</span>
				<input
					ref={ref}
					{...props}
					className={`app-input ${errors && errors[name] ? 'error' : ''} ${
						props.className ? props.className : ''
					}`}
					autoComplete='autocomplete_off_hack_rsp!b'
					onChange={(e) => {
						if (!props.disabled && props.onChange) {
							props.onChange(e);
						}
					}}
				/>
			</div>
		);
	}
);

export default AppInput;
