import React from 'react';
import AppFooter from '../sections/AppFooter';
import AppInput from '../components/AppInput';
import AppButton from '../components/AppButton';
import AppDropdown from '../components/AppDropdown';
import ApiService from '../services/api';
import RouteNames from '../router/RouteNames';
import { useNavigate } from 'react-router-dom';

const registerAsOptions = [
	{ value: 'Investor', label: 'Investor' },
	{ value: 'Business', label: 'Business' },
	{ value: 'Accelerator', label: 'Accelerator' },
];

const revenueOptions = [
	{ value: '< 1M', label: '< 1M' },
	{ value: '1 - 10M', label: '1 - 10M' },
	{ value: '10 - 50M', label: '10 - 50M' },
	{ value: '> 50M', label: '> 50M' },
];

const currencies = [
	{ value: 'USD', label: 'USD' },
	{ value: 'Euro', label: 'Euro' },
];

const Register = () => {
	const navigateTo = useNavigate();

	const [name, setName] = React.useState('');
	const [surname, setSurname] = React.useState('');
	const [company, setCompany] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [registerAs, setRegisterAs] = React.useState(null);
	const [revenue, setRevenue] = React.useState(null);
	const [currency, setCurrency] = React.useState(currencies[0]);
	const [phone, setPhone] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const [step, setStep] = React.useState(0);

	const register = (event) => {
		event.preventDefault();
		const payload = {
			FirstName: name,
			LastName: surname,
			Company: company,
			Email: email,
			RegisterAs: registerAs.value,
			Revenue: revenue.value,
			Currency: currency.value,
			Phone: phone,
		};
		setLoading(true);

		ApiService.post(
			'https://script.google.com/macros/s/AKfycbzrPNYTv49d4c6PBY2xPQza6GCNDn-SRO4ichH2q2QQsJzL9oSzDpVEinXQQyZhdO8EgQ/exec',
			JSON.stringify(payload)
		).then(
			(res) => {
				setLoading(false);
				setStep(1);
			},
			(error) => {
				setLoading(false);
			}
		);
	};

	const renderStep = (index, title, text) => {
		return (
			<div className={'flex flex-1 w-full flex-row relative pb-8 md:pb-24'}>
				{index < 2 && (
					<div className='absolute h-full border-r-2 border-white' style={{ left: '14.5px', zIndex: 0 }} />
				)}
				<div className='flex flex-wrap w-8 h-8 bg-white rounded-full items-center align-middle justify-center z-10 mr-4'>
					<div
						className={`flex w-6 h-6 ${
							index === step ? 'bg-gray-300' : 'bg-primary'
						} rounded-full items-center align-middle justify-center`}
					>
						<div
							className={`h-2 w-2 ${index === step ? 'bg-secondary' : 'bg-white'} rounded-full z-50`}
						></div>
					</div>
				</div>
				<div className={'flex flex-col'}>
					<p className={'gotham text-white text-[18px] font-medium'} style={{ lineHeight: '27px' }}>
						{title}
					</p>
					<p className={'inter text-white text-[16px]'} style={{ lineHeight: '24px' }}>
						{text}
					</p>
				</div>
			</div>
		);
	};

	const renderForm = () => {
		return (
			<form className='flex flex-1 md:w-1/2 mx-4' style={{ backgroundColor: '#FCFCFD' }} onSubmit={register}>
				<div className='bg-white rounded-lg mx-auto my-20 p-4 shadow-md' style={{ maxWidth: '500px' }}>
					<p className='text-primary text-[30px] font-medium text-center'>Create an Account</p>
					<div className='flex flex-row gap-4 mt-4'>
						<AppInput
							placeholder={'First name'}
							value={name}
							onChange={(event) => setName(event.target.value)}
						/>
						<AppInput
							placeholder={'Last name'}
							value={surname}
							onChange={(event) => setSurname(event.target.value)}
						/>
					</div>
					<AppInput
						containerClassName='mt-6'
						placeholder={'Company name'}
						value={company}
						onChange={(event) => setCompany(event.target.value)}
					/>
					<AppInput
						containerClassName='mt-6'
						placeholder={'Email address'}
						value={email}
						onChange={(event) => setEmail(event.target.value)}
					/>

					<AppDropdown
						placeholder={'Register as'}
						containerClassName={'mt-6'}
						options={registerAsOptions}
						value={registerAs}
						onChange={setRegisterAs}
						maxMenuHeight={400}
					/>

					<div className='flex flex-row mt-6'>
						<div className='flex flex-1'>
							<AppDropdown
								containerClassName={'w-full'}
								placeholder={'Revenue'}
								options={revenueOptions}
								value={revenue}
								onChange={setRevenue}
								maxMenuHeight={400}
								showDropdownIndicator={false}
							/>
						</div>
						<AppDropdown
							placeholder={'&nbsp;'}
							options={currencies}
							value={currency}
							onChange={setCurrency}
							maxMenuHeight={400}
						/>
					</div>

					<AppInput
						containerClassName='mt-6'
						placeholder={'Phone number'}
						value={phone}
						onChange={(event) => setPhone(event.target.value)}
					/>

					<AppButton
						loading={loading}
						type={'secondary mt-6 w-full'}
						text={'Request Access'}
						buttonType={'submit'}
					/>

					<p className={'inter text-primary text-[16px] mt-4'}>
						By clicking Request Access, you have read and acknowledge our{' '}
						<a
							onClick={() => navigateTo(RouteNames.Privacy)}
							className={'cursor-pointer text-secondary font-medium'}
						>
							Terms of User
						</a>{' '}
						and{' '}
						<a
							onClick={() => navigateTo(RouteNames.Privacy)}
							className={'cursor-pointer text-secondary font-medium'}
						>
							Privacy Policy
						</a>
						.
					</p>
				</div>
			</form>
		);
	};

	const registered = () => {
		return (
			<div className='flex flex-1 md:w-1/2 mx-4' style={{ backgroundColor: '#FCFCFD' }}>
				<div
					className='bg-white rounded-lg mx-auto my-20 p-4 shadow-md items-center text-center'
					style={{ maxWidth: '500px' }}
				>
					<img src={`/img/svg/check-complete.svg`} alt='' className={'mx-auto my-8'} />
					<p className='text-primary text-[30px] font-medium text-center mb-4'>
						Request submitted successfully
					</p>
					<p className='text-primary text-[20px] text-center'>
						We successfully received your submission. We will review the information you submitted and get
						back at you as soon as possible.
					</p>

					<AppButton onClick={() => navigateTo(RouteNames.Homepage)} text={'Go Back'} type={'mt-8 mx-auto'} />
				</div>
			</div>
		);
	};

	const renderContent = () => {
		if (step === 0) {
			return renderForm();
		}
		return registered();
	};

	return (
		<>
			<div className={'flex flex-col'}>
				<div className='flex flex-col md:flex-row'>
					<div className='flex flex-1 w-full md:w-1/2 relative'>
						<img
							className={'w-full absolute z-0 h-full object-cover'}
							src='/img/image.png'
							alt=''
							style={{ zIndex: -1 }}
						/>
						<div className={'flex flex-col flex-1 py-20 px-8 md:px-12 lg:px-20 xl:px-32'}>
							<img src='/img/logo.svg' alt='' className={'w-96 mb-16'} />
							<div className='flex flex-col'>
								{renderStep(0, 'Register', 'Please provide your personal information')}
								{renderStep(1, 'Verify your account', 'Verify your MoneysClub account ')}
								{renderStep(2, 'Invite trust members', 'Start collaborating with the Club')}
							</div>
						</div>
					</div>
					{renderContent()}
				</div>
				<AppFooter />
			</div>
		</>
	);
};

export default Register;
