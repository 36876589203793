import React from 'react';
import AppCardItem from '../components/AppCardItem';

const AppBusinessNeeds = () => {
	return (
		<div className='container flex flex-1 flex-col flex-wrap items-center justify-between w-full mx-auto py-12'>
			<p
				className={'gotham text-[36px] font-semibold text-gray-900 text-center mx-4'}
				style={{ maxWidth: '650px' }}
			>
				One closed and exclusive club to find the best solution your business needs.
			</p>
			<p className={'text-center text-gray-600 mt-8 mx-4'} style={{ maxWidth: '500px' }}>
				Powerful, self-serve product and growth analytics to help you convert, engage and retain more users.
				Trusted by over 4,000 startups.
			</p>
			<div className='flex flex-1 flex-col gap-8 w-full mt-12 px-4'>
				<div className='flex flex-col md:flex-row gap-8'>
					<div className='flex flex-1'>
						<AppCardItem
							icon={'users-up'}
							text={'Investors'}
							description={
								'Accelerate your growth. Discover and source strategic deals quickly and efficiently.'
							}
						/>
					</div>
					<div className='flex flex-1'>
						<AppCardItem
							icon={'atom'}
							text={'VC'}
							description={
								'Originate proprietary deal flow. Source investment opportunities directly from CEOs.'
							}
						/>
					</div>
					<div className='flex flex-1'>
						<AppCardItem
							icon={'home-smile'}
							text={'Real Estate'}
							description={
								'Originate proprietary deal flow. Source investment opportunities directly from CEOs.'
							}
						/>
					</div>
				</div>
				<div className='flex flex-col md:flex-row gap-8'>
					<div className='flex flex-1'>
						<AppCardItem
							icon={'coin-swap'}
							text={'M&A'}
							description={
								'Grow your wealth. Access investments, M&A and real estate deals confidentially.'
							}
						/>
					</div>
					<div className='flex flex-1'>
						<AppCardItem
							icon={'dataflow'}
							text={'Business Development'}
							description={
								'Expand your market, at home and abroad, with new clients, suppliers and investors.'
							}
						/>
					</div>
					<div className='flex flex-1'>
						<AppCardItem
							icon={'message-heart-circle'}
							text={'Family Office & UHNWI'}
							description={
								'Grow your wealth. Access investments, M&A and real estate deals confidentially.'
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppBusinessNeeds;
