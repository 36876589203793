import React from 'react';
import AppButton from '../components/AppButton';

const AppRegister = ({ onRegister }) => {
	return (
		<div style={{ backgroundColor: '#F9FAFB' }}>
			<div className={'flex flex-1 flex-col items-center justify-center py-20 mx-4'}>
				<p className={'gotham text-[36px] font-semibold text-center'}>
					Is the network a good fit for your business goals?
				</p>

				<div className={'flex flex-col md:flex-row gap-4 mt-8'}>
					<AppButton onClick={onRegister} text={'Apply for membership'} />
					<AppButton onClick={onRegister} text={'Request access'} type={'dark'} />
				</div>
			</div>
		</div>
	);
};

export default AppRegister;
