import React from 'react';
import AppHeader from '../sections/AppHeader';
import AppHero from '../sections/AppHero';
import AppSponsors from '../sections/AppSponsors';
import AppBusinessNeeds from '../sections/AppBusinessNeeds';
import AppTestimonials from '../sections/AppTestimonials';
import AppFeature from '../sections/AppFeature';
import AppNumbers from '../sections/AppNumbers';
import AppRegister from '../sections/AppRegister';
import AppFooter from '../sections/AppFooter';
import RouteNames from '../router/RouteNames';
import { useNavigate } from 'react-router-dom';

const Homepage = () => {
	const navigateTo = useNavigate();

	const onRegister = () => {
		navigateTo(RouteNames.Register);
	};

	return (
		<>
			<AppHeader onRegister={onRegister} />
			<AppHero onRegister={onRegister} />
			<AppSponsors />
			<AppBusinessNeeds />
			<AppTestimonials />
			<AppFeature />
			<AppNumbers />
			<AppRegister onRegister={onRegister} />
			<AppFooter />
		</>
	);
};

export default Homepage;
