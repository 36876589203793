const AppMenuItem = ({ text, onClick, isActive }) => {
	const renderContent = () => {
		if (typeof text === 'string') {
			return text;
		}
		return text();
	};

	return (
		<div className={'cursor-pointer hidden md:flex items-center mx-3'} onClick={onClick}>
			<p className={`app-header-item`}>{renderContent()}</p>
		</div>
	);
};
export default AppMenuItem;
