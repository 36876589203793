import React from 'react';
import AppIcon from './AppIcon';

const AppButton = ({ text, type = 'primary', onClick, buttonType = 'button', disabled = false, loading }) => {
	return (
		<button
			type={buttonType}
			className={`app-button relative ${type}`}
			onClick={onClick}
			disabled={disabled || loading}
		>
			{!!loading && <AppIcon icon={'loader-4-line spinner absolute left-0 ml-4'} />}
			{text}
		</button>
	);
};

export default AppButton;
