import React from 'react';
import Select from 'react-select';
import AppIcon from './AppIcon';

const AppDropdown = React.forwardRef(
	(
		{
			label,
			actionIcon,
			tooltip,
			onAction,
			actionId,
			placeholder = '',
			showErrorMessage = true,
			showDropdownIndicator = true,
			errors = {},
			defaultErrorMessage = '',
			containerClassName = '',
			...props
		},
		ref
	) => {
		const { name } = props;

		const hasValue = React.useMemo(() => {
			return props.value && props.value.value;
		}, [props.value]);

		return (
			<div
				className={`app-input-container ${hasValue ? 'input-with-value' : 'input-without-value'} ${
					containerClassName ? containerClassName : ''
				}`}
			>
				<span
					className={`app-text-label ${hasValue ? 'invisible' : 'visible'}`}
					dangerouslySetInnerHTML={{ __html: placeholder }}
				/>
				<Select
					{...props}
					ref={ref}
					placeholder={placeholder}
					classNamePrefix='app-dropdown-prefix'
					className={`app-dropdown flex flex-1 ${props.className} ${errors && errors[name] ? ' error' : ''}`}
					components={{
						IndicatorSeparator: null,
						DropdownIndicator: () =>
							showDropdownIndicator ? (
								<AppIcon icon={'arrow-down-s-line'} className={'text-2xl mx-2 text-gray-500'} />
							) : null,
						// LoadingIndicator: () => <AppLoading height={'h-4'} size={'text-sm'} />,
					}}
					styles={{
						option: (provided) => ({
							...provided,
							fontSize: '14px',
						}),
					}}
				/>
			</div>
		);
	}
);

export default AppDropdown;
