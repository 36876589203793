import React from 'react';

const CustomIcon = ({ icon }) => {
	return (
		<div className={'bg-blue-100 w-14 h-14 rounded-full items-center justify-center align-middle'}>
			<div className={'bg-blue-200 w-10 h-10 rounded-full items-center justify-center align-middle mx-auto mt-2'}>
				<div className={'p-[10px]'}>
					<img src={`/img/svg/${icon}.svg`} alt='' />
				</div>
			</div>
		</div>
	);
};

export default CustomIcon;
