import React from 'react';
import CustomIcon from '../components/CustomIcon';

const AppNumbers = () => {
	return (
		<div className={'flex flex-1 flex-col items-center justify-center mx-4'}>
			<CustomIcon icon={'zap-fast'} />
			<p className={'gotham text-[36px] font-semibold text-center'} style={{ maxWidth: '700px' }}>
				More than 10 billion deals across 50 industries choose MoneysClub
			</p>
			<p className={'text-gray-600 text-[20px] text-center mt-6'} style={{ maxWidth: '700px' }}>
				Everything you need to convert, engage, and retain more users.
			</p>

			<div className={'flex flex-1 flex-col md:flex-row gap-8 mt-6'}>
				<div className='flex flex-col md:pr-10 lg:pr-20'>
					<h1 className='gotham text-[40px] md:text-[60px] text-gray-900 text-center'>50+</h1>
					<p className={'text-gray-900 text-[18px] text-center'}>Industries</p>
				</div>
				<div className='flex flex-col md:px-10 lg:px-20 md:border-x-1 border-gray-300'>
					<h1 className='gotham text-[40px] md:text-[60px] text-gray-900 text-center'>10B</h1>
					<p className={'text-gray-900 text-[18px] text-center'}>Live deal flow</p>
				</div>
				<div className='flex flex-col md:pl-10 lg:pl-20'>
					<h1 className='gotham text-[40px] md:text-[60px] text-gray-900 text-center'>100+</h1>
					<p className={'text-gray-900 text-[18px] text-center'}>Countries</p>
				</div>
			</div>

			<div className='mt-16 mx-auto w-3/4 border-b-1 border-gray-300 h-1' />
		</div>
	);
};

export default AppNumbers;
