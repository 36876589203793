import React from 'react';
import AppButton from '../components/AppButton';
import AppMenuItem from '../components/AppMenuItem';

const routes = [
	{
		path: '#',
		text: 'Our Circle',
	},
	{
		path: '#club',
		text: 'Club',
	},
	{
		path: '#testimonials',
		text: 'Testimonials',
	},
];

const AppHeader = ({ onRegister }) => {
	return (
		<nav className='app-header'>
			<div className='desktop-container'>
				<div className='flex justify-between'>
					<div className='flex flex-1 w-full'>
						<div className='flex logo-container'>
							<img src='/img/logo.svg' alt='' />
						</div>

						<div className={'hidden md:flex flex-1 items-center'}>
							<div className='flex flex-1'>
								{routes.map((route) => (
									<AppMenuItem
										key={route.path}
										text={route.text}
										// onClick={() => navigateTo(route.path)}
										isActive={false}
									/>
								))}
							</div>
							<div className={'flex-row gap-4 hidden lg:flex'}>
								<AppButton onClick={onRegister} text={'Apply for membership'} />
								<AppButton onClick={onRegister} text={'Request access'} type={'secondary'} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default AppHeader;
