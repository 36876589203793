import React from 'react';
import CustomIcon from '../components/CustomIcon';
import AppIcon from '../components/AppIcon';

const testimonials = [
	{
		comment:
			'“Money network puts my business in front of the right people. It has connected me with skilled investors resulting in $300,000 in seed capital.”',
		image: '/img/testimonial.png',
		author: 'Kieran Flanagan',
		role: 'VP of Marketing at HubSpot',
	},
];

const AppTestimonials = () => {
	const [index, setIndex] = React.useState(0);

	const testimonial = React.useMemo(() => {
		return testimonials[index];
	}, [index]);

	const prev = () => {
		if (index - 1 < 0) {
			setIndex(testimonials.length - 1);
		} else {
			setIndex(index - 1);
		}
	};

	const next = () => {
		if (index + 1 >= testimonials.length) {
			setIndex(0);
		} else {
			setIndex(index + 1);
		}
	};

	return (
		<div style={{ backgroundColor: '#F9FAFB' }}>
			<div className={'container flex flex-1 flex-col items-center justify-center py-16 mx-auto'}>
				<div className={'flex flex-col items-center justify-center'}>
					<CustomIcon icon={'logomark'} />
				</div>

				<h1 className='gotham text-[40px] md:text-[48px] text-gray-900 text-center'>{testimonial.comment}</h1>
				<img src={testimonial.image} alt='' className={'w-14 h-14 rounded-full object-contain my-6'} />

				<div className={'flex flex-row items-center'}>
					<div
						onClick={prev}
						className='w-12 h-12 rounded-full items-center bg-white text-center justify-center align-middle border-1 border-gray-300 cursor-pointer'
					>
						<AppIcon icon={'arrow-left-line'} className={'text-[25px]'} style={{ lineHeight: '45px' }} />
					</div>
					<div className={'mx-8'}>
						<p className={'text-center text-gray-900 text-[16px] font-semibold'}>{testimonial.author}</p>
						<p className={'text-center text-gray-600 text-[16px]'}>{testimonial.role}</p>
					</div>
					<div
						onClick={next}
						className='w-12 h-12 rounded-full items-center bg-white text-center justify-center align-middle border-1 border-gray-300 cursor-pointer'
					>
						<AppIcon icon={'arrow-right-line'} className={'text-[25px]'} style={{ lineHeight: '45px' }} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppTestimonials;
