import React from 'react';
import AppFooter from '../sections/AppFooter';

const Privacy = () => {
	const PrincipleRef = React.useRef();
	const CookiesRef = React.useRef();
	const StorageRef = React.useRef();
	const DataRef = React.useRef();
	const RightsRef = React.useRef();
	const ContactRef = React.useRef();
	const DataProcessRef = React.useRef();

	const TITLE_CLASS = 'title';

	const scrollInto = (ref) => {
		ref.current.scrollIntoView();
	};

	return (
		<>
			<div className={'flex flex-col'}>
				<div className='container flex flex-col md:flex-row px-4 mx-auto py-12'>
					<div className={'privacy-navigation md:fixed'}>
						<p onClick={() => scrollInto(PrincipleRef)}>Privacy Statement</p>
						<p onClick={() => scrollInto(PrincipleRef)}>Principle</p>
						<p onClick={() => scrollInto(CookiesRef)}>Use of the website and cookies</p>
						<p onClick={() => scrollInto(StorageRef)}>Storage of customer data</p>
						<p onClick={() => scrollInto(DataRef)}>Data security</p>
						<p onClick={() => scrollInto(RightsRef)}>Rights of customers</p>
						<p onClick={() => scrollInto(ContactRef)}>Contact</p>
						<p onClick={() => scrollInto(DataProcessRef)}>
							Data processing by third parties in Switzerland and abroad
						</p>
					</div>
					<div className='page-privacy'>
						<p className={TITLE_CLASS} ref={PrincipleRef}>
							Principle
						</p>
						<p>
							The protection of our customers’ privacy is very important to MONEYS CLUB. We set out below
							the principles we follow and for which purpose we collect and process personal data
							(information that determines your identity or allows us to identify you; referred to
							hereinafter also as “data”) via this website, and other websites and applications (referred
							to hereinafter as the "website") operated by MONEYS CLUB.
						</p>
						<p>
							We collect and process your personal data carefully, only for the purposes described in this
							Privacy Statement and only to the extent necessary as defined therein and within the scope
							of the applicable legal regulations. We only store your personal data to the extent and as
							long as it is required for the performance of our services or as we are legally obliged to
							do so.
						</p>
						<p>
							The terms of use for your MONEYS CLUB.com profile and the General Conditions of Carriage for
							the operation of flights also apply.
						</p>
						<p>
							<span className='font-semibold'>Last Revised:</span> January 03, 2023
						</p>

						<p className={TITLE_CLASS} ref={CookiesRef}>
							Use of the website and cookies
						</p>
						<p>
							We only request personal data where required for the performance of our own services and for
							the processing of transactions that you carry out with MONEYS CLUB or our partners..
						</p>
						<p>
							When you access our website, our system automatically records technical information about
							the computer accessing it. The data collected is information about the browser type and the
							version used, the operating system, the internet provider and the IP address of the user,
							the date and time of access, the websites from which the user’s system obtains access to our
							website, and the websites accessed by the user’s system via our website. MONEYS CLUB uses
							so-called cookies to make it easier for you to use the MONEYS CLUB website and other
							websites and applications operated by MONEYS CLUB, and to obtain ideas for improving the
							information and services accessible via the website. Personal data is pseudonymised.
						</p>
						<p>The basis for this data processing is Art. 6(1)(a) and (f) GDPR.</p>

						<p className={TITLE_CLASS} ref={StorageRef}>
							Storage of customer data
						</p>
						<p>
							For accounting reasons, MONEYS CLUB generally stores the personal data collected from
							customers for processing bookings for 10 years after the last processing operation. We store
							other data as long as is necessary for the execution of the contract and to safeguard our
							rights. We reserve the right to apply longer statutory and operational storage requirements
							and reasons.
						</p>
						<p>
							Personal data collected for marketing activities is generally deleted as soon as the purpose
							of the activity has been met.
						</p>
						<p>The basis for this data processing is Art. 6(1)(f) GDPR.</p>

						<p className={TITLE_CLASS} ref={DataRef}>
							Data security
						</p>
						<p>
							We protect your personal data by means of appropriate technical and organisational security
							measures and store it solely on our secure servers. The website is secured against loss,
							destruction and manipulation by means of customary measures in accordance with the state of
							the art and against access, modification or distribution by unauthorised persons. Especially
							sensitive data (e.g. access data for your profile account) is encrypted during transmission
							via the internet (SSL).
						</p>
						<p>
							However, MONEYS CLUB does not assume any guarantee for the security of your data. It is your
							responsibility to make an up-to-date back-up of the transferred data at all times and store
							it separately from the website. You are also responsible for creating a secure password for
							your customer account, keeping it safe and changing it on a regular basis.
						</p>

						<p className={TITLE_CLASS} ref={RightsRef}>
							Rights of customers
						</p>
						<p>
							You have the right to information, correction, erasure and portability of your data. You
							also have the right to restrict processing.Paragraph C (Storage of customer data) and other
							statutory exceptions are reserved.
						</p>
						<p>
							You have the right to object to the processing of your personal data. An objection only has
							future effect and does not affect previous processing. If, on account of the objection,
							MONEYS CLUB is no longer able to perform the services contractually agreed with you, the
							objection is deemed to be a breach of contract by the customer and MONEYS CLUB has the right
							to terminate its contract with you without notice. Payment obligations already contractually
							entered into by the customer remain in force.
						</p>
						<p>
							To claim these rights, you can contact MONEYS CLUB at the address given If MONEYS CLUB does
							not comply with the applicable data protection law, you have the right to file a complaint
							with the competent regulatory authority.
						</p>

						<p className={TITLE_CLASS} ref={ContactRef}>
							Contact
						</p>
						<p>
							If you have any questions about this Privacy Statement or data protection at MONEYS CLUB,
							please contact:
						</p>
						<p className={'font-medium'}>
							Route de la Galaise 34 Route de la Galaise 34, <br />
							1228 Plan-les-Ouates, Geneva - Switzerland.
						</p>
						<p>
							<a href='mailto:privacy@moneysclub.co' className={'font-medium'}>
								privacy@moneysclub.co
							</a>
						</p>
						<p>
							We review our Privacy Statement regularly and update it as required. We will inform you of
							any important changes (e.g. on our website).
						</p>

						<p className={TITLE_CLASS} ref={DataProcessRef}>
							Data processing by third parties in Switzerland and abroad
						</p>
						<p>
							MONEYS CLUB allows personal data to be processed by third parties in Switzerland and/or
							abroad (also in countries where legislation does not guarantee appropriate data protection)
							for the purpose of operating the website and to meet the purposes stated in this Privacy
							Statement.
						</p>
						<p>
							In the case of the transfer of data to countries without appropriate data protection, MONEYS
							CLUB ensures that the measures required are implemented (generally by signing recognised
							data protection agreements, e.g. based on standard contractual clauses of the EU), in order
							to protect personal data in accordance with the applicable data protection law.
						</p>
					</div>
				</div>
				<AppFooter />
			</div>
		</>
	);
};

export default Privacy;
