import React from 'react';
import AppPill from '../components/AppPill';
import AppCardItem from '../components/AppCardItem';

const features = [
	{
		icon: 'lock',
		text: 'Secure & private access',
		description: 'Share your data confidentiality & verify you profile.',
	},
	{
		icon: 'zap',
		text: 'Post Deal',
		description: 'Share and broadcast your business needs in a closed club.',
	},
	{
		icon: 'message',
		text: 'Contact everyone',
		description: 'Discover and contact the top right people in the world.',
	},
	{
		icon: 'lock-keyhole',
		text: 'MoneysClub secret access',
		description: "Close every deal you won't in a secret club.",
	},
];

const AppFeature = () => {
	return (
		<div className='container flex flex-1 flex-col flex-wrap items-center justify-between w-full px-4 mx-auto py-12'>
			<AppPill text={'Features'} />
			<p className={'gotham text-[36px] font-semibold text-center'}>How MoneysClub Works</p>
			<p className={'text-gray-600 text-[20px] text-center'} style={{ maxWidth: '700px' }}>
				At MoneysClub, we understand and find with AI the right people to match and we simplify the
				communication to deal the right opportunity!
			</p>

			<img
				src='/img/feature.png'
				className={'border-4 border-primary rounded-lg w-full md:w-4/5 mt-6 px-4'}
				alt=''
			/>

			<div className='flex flex-col md:flex-row gap-8 mt-16'>
				{features.map((feature, index) => (
					<AppCardItem key={index} {...feature} />
				))}
			</div>

			<div className='mx-auto w-3/4 border-b-1 border-gray-300 h-1 mt-24 mb-4' />
		</div>
	);
};

export default AppFeature;
