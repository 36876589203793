import React from 'react';

const AppPill = ({ text }) => {
	return (
		<div className={'bg-blue-100 px-3 py-1 rounded-full'}>
			<p className={'inter text-[14px] font-medium'} style={{ color: '#004EEB' }}>
				{text}
			</p>
		</div>
	);
};

export default AppPill;
