import CustomIcon from './CustomIcon';
import React from 'react';

const AppCardItem = ({ icon, text, description }) => {
	return (
		<div className={'flex flex-col items-center justify-center'}>
			<CustomIcon icon={icon} />
			<p className={'text-center my-4 text-gray-900 text-[20px]'}>{text}</p>
			<p className={'text-center text-gray-600 text-[16px]'}>{description}</p>
		</div>
	);
};

export default AppCardItem;
