import React from 'react';
import { useNavigate } from 'react-router-dom';
import RouteNames from '../router/RouteNames';

const AppFooter = () => {
	const navigateTo = useNavigate();

	const headerClass = 'text-[14px] uppercase text-white font-medium mb-3';
	const itemClass = 'text-[14px] text-white font-medium mb-3';

	const goToPrivacy = () => {
		navigateTo(RouteNames.Privacy);
	};

	return (
		<div className='bg-primary'>
			<div className='container flex flex-1 flex-col flex-wrap justify-between w-full mx-auto pt-12 px-4'>
				<div className='flex flex-col md:flex-row gap-4'>
					<div className='flex flex-1 flex-col'>
						<p className={headerClass}>Our Network</p>
						<p className={itemClass}>Investors</p>
						<p className={itemClass}>Accelerators</p>
						<p className={itemClass}>Startups</p>
						<p className={itemClass}>Businesses</p>
						<p className={itemClass}>M&A</p>
						<p className={itemClass}>Business Development</p>
					</div>

					<div className='flex flex-1 flex-col'>
						<p className={headerClass}>Solution</p>
						<p className={itemClass}>Wallet</p>
						<p className={itemClass}>Data</p>
						<p className={itemClass}>SMEs & Corporate</p>
						<p className={itemClass}>VC & Private Equity</p>
						<p className={itemClass}>Family Office & UHNWI</p>
						<p className={itemClass}>Support</p>
					</div>

					<div className='flex flex-1 flex-col'>
						<p className={headerClass}>Social</p>
						<p className={itemClass}>Twitter</p>
						<p className={itemClass}>LinkedIn</p>
						<p className={itemClass}>Facebook</p>
						<p className={itemClass}>AngelList</p>
					</div>

					<div className='flex flex-1 flex-col'>
						<p className={headerClass}>Legal</p>
						<p className={`${itemClass} cursor-pointer`} onClick={goToPrivacy}>
							Terms
						</p>
						<p className={`${itemClass} cursor-pointer`} onClick={goToPrivacy}>
							Privacy
						</p>
						<p className={itemClass}>Cookies</p>
						<p className={itemClass}>Licenses</p>
						<p className={itemClass}>Settings</p>
						<p className={itemClass}>Contact</p>
					</div>

					<div className='flex flex-1 flex-col'>
						<p className={headerClass}>Company</p>
						<p className={itemClass}>About us</p>
						<p className={itemClass}>Careers</p>
					</div>
				</div>

				<div className='mx-auto border-b-1 w-full border-gray-600 h-1' />

				<div className={'flex flex-1 flex-col md:flex-row items-center my-6 gap-5'}>
					<div className='flex flex-1'>
						<img src='/img/logo.svg' alt='' />
					</div>

					<p className={'inter text-[14px] text-white'}>© 2023 MoneysClub. All rights reserved.</p>
				</div>
			</div>
		</div>
	);
};

export default AppFooter;
