import React from 'react';
import { HashRouter as AppRouter, Route, Routes } from 'react-router-dom';
import RouteNames from './RouteNames';
import Homepage from '../pages/Homepage';
import Privacy from '../pages/Privacy';
import Register from '../pages/Register';
import AppScrollToTop from '../components/AppScrollTop';

const Router = () => {
	return (
		<AppRouter>
			<AppScrollToTop />
			<Routes>
				<Route exact path={RouteNames.Register} element={<Register />} />
				<Route exact path={RouteNames.Privacy} element={<Privacy />} />
				<Route path={'*'} element={<Homepage />} />/
			</Routes>
		</AppRouter>
	);
};

export default Router;
