import React from 'react';

const sponsors = [
	'/img/image-239@2x.png',
	'/img/-citypng-2@2x.png',
	'/img/-citypng-1@2x.png',
	'/img/-citypng-4@2x.png',
	'/img/-citypng-3@2x.png',
	'/img/image-240@2x.png',
];

const AppSponsors = () => {
	return (
		<div style={{ backgroundColor: '#F9FAFB' }}>
			<div className='container flex flex-1 flex-col flex-wrap items-center justify-between w-full mx-auto py-12'>
				<p className={'text-center inter text-[18px]'}>
					Over <span className='font-semibold'>5,000 VIP</span> leaders trust in our worldwide network.
				</p>
				<div className='flex flex-1 flex-col md:flex-row mt-4 gap-8 md:gap-12'>
					{sponsors.map((sponsor, index) => {
						return (
							<div key={index} className={'flex flex-1 mx-auto'}>
								<img className={'object-contain w-36'} src={sponsor} alt={''} />
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default AppSponsors;
