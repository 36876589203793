import React from 'react';
import AppButton from '../components/AppButton';

const AppHero = ({ onRegister }) => {
	return (
		<div className='relative bg-primary px-4 py-3 overflow-hidden'>
			<div className='container flex flex-1 flex-wrap items-center justify-between w-full mx-auto bg-primary py-12'>
				<div className='absolute left-0' style={{ bottom: '-50%' }}>
					<img className='light-accent' src='/img/light-accent.png' alt='Light accent' />
				</div>
				<div className='flex flex-1 flex-col z-50'>
					<div className='flex flex-1'>
						<div className='flex flex-col lg:flex-row'>
							<div className='flex flex-1 flex-col'>
								<h1 className='gotham text-[40px] md:text-[60px] text-white'>
									Grow your Business with the Right Connections
								</h1>
								<p className={'inter text-[16px] md:text-[20px] text-white'}>
									The best innovative matching marketplace platform to raise and invest capital,
									secure new opportunity, sell product & service, finalize M&A and best real estate
									deals.
								</p>
								<div className={'flex flex-col md:flex-row gap-4 mt-12 mb-4'}>
									<AppButton onClick={onRegister} text={'Apply for membership'} />
									<AppButton onClick={onRegister} text={'Request access'} type={'secondary'} />
								</div>
							</div>
							<div className='flex flex-1 h-[400px] mx-auto'>
								<img className={'mx-auto'} src='/img/moneys-club.svg' alt='Moneys Club' />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppHero;
